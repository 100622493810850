import { sortBy } from './sort-by';

export interface SelectOption {
  label: string;
  value: string | null;
  disabled?: boolean;
}

function getSelectboxOptionsFromConstants(
  constants: any,
  emptyOption = true,
  emptyLabel = $localize `:@@shared.filter_column.not_set:Not set`,
  translationPrefix = '',
  sort = true
): SelectOption[] {
  let options = [];

  for (const key of Object.keys(constants)) {
    options.push({ label: translationPrefix + constants[key], value: key });
  }

  if (sort) {
    options = sortBy(options, option => option.label.toLowerCase());
  }

  if (emptyOption !== false) {
    options.unshift({ label: emptyLabel, value: emptyOption === true ? '' : emptyOption });
  }

  return options;
}

export { getSelectboxOptionsFromConstants };

function getSelectboxOptionsFromCollection(
  collection: unknown[],
  labelField: string,
  valueField: string,
  emptyOption = true,
  emptyLabel = '',
  valueAsNumber = false
): SelectOption[] {
  let options = [];

  if (emptyOption) {
    options.push({ label: emptyLabel, value: null });
  }

  if (Array.isArray(collection)) {
    collection.forEach((model: any) => {
      options.push({ label: model[labelField], value: valueAsNumber ? Number(model[valueField]) : model[valueField] });
    });
  }

  options = sortBy(options, option => option.label.toLowerCase());

  if (emptyOption) {
    options.unshift({ label: emptyLabel, value: '' });
  }

  return options;
}

export { getSelectboxOptionsFromCollection };
